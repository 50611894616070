import { TProducts } from '@customTypes/entities'

import Api from '.'

class Product extends Api {
	getAll(params?: GenericObject): Promise<TProducts> {
		return this.get('ebook', {
			addAuthorization: true,
			params: {
				page: 1,
				limit: 1000,
				...params,
			},
		})
	}

	create(body: FormData) {
		return this.post('ebook', {
			addAuthorization: true,
			body,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}

	update(id: string, body: FormData) {
		return this.patch(`ebook/${id}`, {
			addAuthorization: true,
			body,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}

	deleteById(id: string) {
		return this.delete(`ebook/${id}`, {
			addAuthorization: true,
		})
	}
}

export default Product
