import { TDiscountBand } from '@customTypes/entities'

import { TCartItem, TCountAndPriceTuple } from './types'

const getPrice = ({ tickets, ticketValue }: Pick<TCartItem, 'tickets' | 'ticketValue'>) => {
	return tickets * ticketValue
}

const getDiscountedPrice = ({
	tickets,
	ticketValue,
	discountBands,
	discountFirstUsers,
	useDiscountFirstUsers,
}: TCartItem) => {
	const price = getPrice({ tickets, ticketValue })

	const currentDiscountBand = discountBands?.reduce<TDiscountBand | undefined>(
		(currentDiscountBand, discountBand) => {
			if (tickets >= discountBand.minTickets) return discountBand
			return currentDiscountBand
		},
		undefined
	)

	const discountPercentage = currentDiscountBand?.discountPercent ?? 0
	const discountFlat =
		useDiscountFirstUsers && discountFirstUsers ? discountFirstUsers.discountValue : 0

	const discountedPrice = price - price * discountPercentage - discountFlat

	if (discountedPrice < 0) return 0
	return discountedPrice
}

const toCountAndPrice = (current: TCountAndPriceTuple, item: TCartItem): TCountAndPriceTuple => {
	const price = getPrice(item)
	const discountedPrice = getDiscountedPrice(item)
	return [current[0] + item.tickets, current[1] + price, current[2] + discountedPrice]
}

export default { toCountAndPrice }
