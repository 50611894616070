import { TAffiliatePayload } from './types'

import Api from '.'

class Affiliate extends Api {
	getAll(params?: GenericObject) {
		return this.get('affiliate', {
			addAuthorization: true,
			params: {
				page: 1,
				limit: 1000,
				...params,
			},
		}).catch((e) => {
			console.log(e)
			return []
		})
	}

	create({
		name,
		nationalId,
		email,
		cellphone,
		revShare,
		isActive,
		zipcode,
		state,
		city,
		neighborhood,
		street,
		addressNumber,
		complement,
	}: TAffiliatePayload) {
		return this.post('affiliate', {
			addAuthorization: true,
			body: {
				name,
				nationalId,
				email,
				cellphone,
				revShare: revShare / 100,
				isActive,
				address: {
					zipcode,
					state,
					city,
					neighborhood,
					street,
					addressNumber,
					complement,
				},
			},
		})
	}

	update(
		id: string,
		{
			name,
			nationalId,
			email,
			cellphone,
			revShare,
			isActive,
			zipcode,
			state,
			city,
			neighborhood,
			street,
			addressNumber,
			complement,
		}: TAffiliatePayload
	) {
		return this.patch(`affiliate/${id}`, {
			addAuthorization: true,
			body: {
				name,
				nationalId,
				email,
				cellphone,
				revShare: revShare / 100,
				isActive,
				address: {
					zipcode,
					state,
					city,
					neighborhood,
					street,
					addressNumber,
					complement,
				},
			},
		})
	}

	deleteById(id: string) {
		return this.delete(`affiliate/${id}`, {
			addAuthorization: true,
		})
	}
}

export default Affiliate
