import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { api } from '@services'
import {
	TAuthResponse,
	TCompleteRegistrationPayload,
	TForgotPasswordPayload,
	TResetPasswordPayload,
	TSignInPayload,
	TSignUpPayload,
	TUpdatePasswordPayload,
} from '@services/api/types'

import helpers from './helpers'

import { TContext, TSessionUser } from './types'

const UserContext = createContext<TContext>({
	user: undefined,
	isAuthenticated: undefined,
	isAdmin: false,
	loadUser: () => {},
	register: async () => {},
	completeRegistration: async () => {},
	login: async () => {},
	logout: () => {},
	forgotPassword: async () => {},
	resetPassword: async () => {},
	updatePassword: async () => {},
})

export const UserProvider = ({ children }: React.PropsWithChildren) => {
	const [user, setUser] = useState<TSessionUser>()
	const isAuthenticated = user !== undefined ? Boolean(user) : undefined
	const isAdmin = user?.role === 'ADMIN'

	const authenticate = useCallback((data: TAuthResponse) => {
		helpers.saveAccessToken(data)

		const user = data?.user
		if (user) {
			const { cpf, email, fullName, wallet, isRegisterComplete, phone, role } = user
			setUser({
				cpf,
				email,
				phone,
				name: fullName,
				walletBalance: wallet?.balance ?? 0,
				isRegisterComplete,
				role,
			})
		} else {
			setUser(null)
		}
	}, [])

	const loadUser = useCallback(() => {
		const token = helpers.getValidationToken()
		const authFlow = token ? api.user.activateUser(token) : api.user.me()
		authFlow.then(authenticate).catch()
	}, [authenticate])

	const register = useCallback((payload: TSignUpPayload) => {
		const affiliateId = helpers.getAffiliateId()
		return api.user.signUp({ affiliateId, ...payload })
	}, [])

	const completeRegistration = useCallback(
		(payload: TCompleteRegistrationPayload) => {
			return api.user.completeRegistration(payload).then(loadUser)
		},
		[loadUser]
	)

	const login = useCallback(
		(payload: TSignInPayload) => {
			return api.user.signIn(payload).then(authenticate)
		},
		[authenticate]
	)

	const forgotPassword = useCallback((payload: TForgotPasswordPayload) => {
		return api.user.forgotPassword(payload)
	}, [])

	const resetPassword = useCallback((payload: TResetPasswordPayload) => {
		return api.user.resetPassword(payload)
	}, [])

	const updatePassword = useCallback((payload: TUpdatePasswordPayload) => {
		return api.user.updatePassword(payload)
	}, [])

	const logout = useCallback(() => {
		helpers.deleteAccessToken()
		setUser(null)
	}, [])

	useEffect(loadUser, [loadUser])

	const contextValue = useMemo(
		() => ({
			user,
			isAuthenticated,
			isAdmin,
			loadUser,
			register,
			completeRegistration,
			login,
			logout,
			forgotPassword,
			resetPassword,
			updatePassword,
		}),
		[
			user,
			isAuthenticated,
			isAdmin,
			loadUser,
			register,
			completeRegistration,
			login,
			logout,
			forgotPassword,
			resetPassword,
			updatePassword,
		]
	)

	return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}

export const useUserContext = () => useContext(UserContext)
