import { TPayment, TPaymentMethod, TPaymentPayload, TPurchaseHistory } from '@customTypes/entities'

import Api from '.'

class Payment extends Api {
	getPaymentStatus(id: string): Promise<TPayment> {
		return this.get(`payment/pix/${id}`, {
			addAuthorization: true,
		})
	}

	getPurchaseHistory(params?: GenericObject): Promise<TPurchaseHistory> {
		return this.get('payment', {
			addAuthorization: true,
			params: {
				page: 1,
				limit: 1000,
				...params,
			},
		})
	}

	finalize(payments: Array<TPaymentPayload>, method: TPaymentMethod): Promise<TPayment> {
		return this.post(`payment/${method}`, {
			addAuthorization: true,
			body: {
				payments,
			},
		})
	}

	sendToEmail() {
		return this.get('payment/history/send-mail', {
			addAuthorization: true,
		})
	}
}

export default Payment
