import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { api } from '@services'

import helpers from './helpers'

import { DEFAULT_CUSTOMIZATIONS } from './constants'

import { TContext } from './types'

const CustomizationContext = createContext<TContext>({
	isLoading: false,
	customizations: DEFAULT_CUSTOMIZATIONS,
	setupCustomizations: async () => {},
})

export const CustomizationProvider = ({ children }: React.PropsWithChildren) => {
	const [customizations, setCustomizations] = useState(helpers.getStoredCustomizations())
	const [isLoading, setIsLoading] = useState(false)

	const setupCustomizations = useCallback(async () => {
		setIsLoading(true)

		const apiCustomizations = await api.customization.getData()
		const storedCustomizations = helpers.getStoredCustomizations()
		const currentCustomizations = apiCustomizations || storedCustomizations || DEFAULT_CUSTOMIZATIONS

		helpers.saveCurrentCustomizations(currentCustomizations)
		setCustomizations(currentCustomizations)
		setIsLoading(false)
	}, [])

	useEffect(() => {
		setupCustomizations()
	}, [setupCustomizations])

	useEffect(() => {
		helpers.createColors(customizations)
	}, [customizations])

	const contextValue = useMemo(
		() => ({ isLoading, customizations, setupCustomizations }),
		[isLoading, customizations, setupCustomizations]
	)

	return (
		<CustomizationContext.Provider value={contextValue}>{children}</CustomizationContext.Provider>
	)
}

export const useCustomizationContext = () => useContext(CustomizationContext)
