import { TPayment, TPurchaseHistory } from '@customTypes/entities'

import { TTickets } from './types'

const getPaidPurchases = (purchases: TPurchaseHistory) => {
	return purchases
		.filter(({ status }) => status === 'PAID')
		.reduce((currentPaidPurchases: TTickets, paidPurchase: TPayment) => {
			const tickets: TTickets = []

			paidPurchase.tickets.forEach((ticket) => {
				const alreadyAdded = tickets.find(({ campaignId }) => ticket.campaignId === campaignId)

				if (alreadyAdded) {
					alreadyAdded.count++
					alreadyAdded.numbers.push(ticket.ticketNumber)
				} else {
					tickets.push({
						count: 1,
						campaignId: ticket.campaignId,
						value: ticket.value,
						date: paidPurchase.updatedAt,
						numbers: [ticket.ticketNumber],
					})
				}
			})

			return [...currentPaidPurchases, ...tickets]
		}, [])
}

export default { getPaidPurchases }
