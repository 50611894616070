import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { api } from '@services'

import { TCampaigns } from '@customTypes/entities'

import { TContext } from './types'

const CampaignContext = createContext<TContext>({
	campaigns: [],
	noCampaigns: false,
	getById: () => undefined,
})

export const CampaignProvider = ({ children }: React.PropsWithChildren) => {
	const [campaigns, setCampaigns] = useState<TCampaigns>([])
	const [noCampaigns, setNoCampaigns] = useState(false)

	const getById = useCallback(
		(id?: string) => campaigns.find((campaigns) => campaigns._id === id),
		[campaigns]
	)

	useEffect(() => {
		api.campaign.getAll({ query: { status: 'STARTED' }, limit: 10 }).then((data) => {
			if (data.length) setCampaigns(data)
			else setNoCampaigns(true)
		})
	}, [])

	const contextValue = useMemo(
		() => ({ campaigns, noCampaigns, getById }),
		[campaigns, noCampaigns, getById]
	)

	return <CampaignContext.Provider value={contextValue}>{children}</CampaignContext.Provider>
}

export const useCampaignContext = () => useContext(CampaignContext)
