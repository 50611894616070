import { TAuthResponse } from '@services/api/types'

import DOM from '@utils/DOM'

const saveAccessToken = (data: TAuthResponse) => {
	const accessToken = data?.access_token
	if (accessToken) {
		localStorage.setItem('access-token', accessToken)
	}
}

const deleteAccessToken = () => {
	localStorage.removeItem('access-token')
}

const getAffiliateId = () => {
	return localStorage.getItem('affiliate') ?? undefined
}

const getValidationToken = () => {
	const searchParams = new URLSearchParams(window.location.search)
	const token = searchParams.get('activate')
	if (token) DOM.resetURL()

	return token
}

export default { saveAccessToken, deleteAccessToken, getAffiliateId, getValidationToken }
