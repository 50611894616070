const getNumericValue = (value: unknown) => {
	if (value && typeof value === 'number') return value
	return 0
}

const extractDigits = (value?: string) => {
	if (!value) return ''
	return value.replace(/[^\d]+/g, '')
}

const formatWithThousandsSeparator = (num: number) => num.toLocaleString('pt-BR')

const formatCurrency = (num: number, currency: string = 'BRL') => {
	const value = isNaN(num) ? 0 : num
	return value.toLocaleString('pt-BR', {
		style: 'currency',
		currency,
		minimumFractionDigits: 2,
	})
}

const formatPhone = (num?: string) => {
	if (!num) return num
	const digits = num.split('')
	const phone = digits.reduce((mask, digit) => mask.replace('#', digit), '(##) #####-####')
	return phone
}

export default {
	extractDigits,
	getNumericValue,
	formatWithThousandsSeparator,
	formatCurrency,
	formatPhone,
}
