import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import { TContext, THistory, TModalContentName } from './types'

// TODO update state management
/*

Agora o estado sera um objeto, com cada chave sendo o nome de um modal e seu o valor sendo um objeto com as propriedades do estado do modal
Cada modal terá o seu estado fortemente tipado
Criar função no Context para que cada modal pegue seu estado

Objetivo de persistir o estado de cada modal do histórico

*/
const ModalContext = createContext<TContext>({
	isOpen: false,
	content: undefined,
	state: {},
	open: () => () => {},
	close: () => {},
	navigate: () => () => {},
	previous: () => {},
})

export const ModalProvider = ({ children }: React.PropsWithChildren) => {
	const [isOpen, setIsOpen] = useState(false)
	const [history, setHistory] = useState<THistory>([])
	const content = history.length ? history[history.length - 1] : undefined

	const [state, setState] = useState<GenericObject>({})

	const updateState = (newState?: GenericObject) => setState(newState ?? {})

	const open = useCallback(
		(contentName: TModalContentName, newState?: GenericObject) => () => {
			if (contentName) {
				setIsOpen(true)
				setHistory([contentName])
				updateState(newState)
			}
		},
		[]
	)

	const close = useCallback(() => {
		setIsOpen(false)
		setHistory([])
		updateState()
	}, [])

	const navigate = useCallback(
		(contentName: TModalContentName, newState?: GenericObject) => () => {
			if (contentName) {
				setHistory((prevHistory) => [...prevHistory, contentName])
				updateState(newState)
			}
		},
		[]
	)

	const previous = useCallback(() => {
		if (history.length > 1) setHistory((prevHistory) => prevHistory.slice(0, -1))
		else close()
	}, [history, close])

	const contextValue = useMemo(
		() => ({ isOpen, content, state, open, close, navigate, previous }),
		[isOpen, content, state, open, close, navigate, previous]
	)

	return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>
}

export const useModalContext = () => useContext(ModalContext)
