import { AxiosInstance, AxiosResponse } from 'axios'

import DOM from '@utils/DOM'

import { TViaCepResponse } from './types'

const states = {
	AC: 'Acre',
	AL: 'Alagoas',
	AP: 'Amapá',
	AM: 'Amazonas',
	BA: 'Bahia',
	CE: 'Ceará',
	DF: 'Distrito Federal',
	ES: 'Espírito Santo',
	GO: 'Goiás',
	MA: 'Maranhão',
	MT: 'Mato Grosso',
	MS: 'Mato Grosso do Sul',
	MG: 'Minas Gerais',
	PA: 'Pará',
	PB: 'Paraíba',
	PR: 'Paraná',
	PE: 'Pernambuco',
	PI: 'Piauí',
	RJ: 'Rio de Janeiro',
	RN: 'Rio Grande do Norte',
	RS: 'Rio Grande do Sul',
	RO: 'Rondônia',
	RR: 'Roraima',
	SC: 'Santa Catarina',
	SP: 'São Paulo',
	SE: 'Sergipe',
	TO: 'Tocantins',
}

class Address {
	http: AxiosInstance | undefined = undefined

	constructor() {
		if (DOM.isClient()) {
			import('axios').then((axios) => {
				this.http = axios.default.create()
				this.http.defaults.baseURL = 'https://viacep.com.br/ws'
				this.http.defaults.headers.common['Content-Type'] = 'application/json'
				this.http.interceptors.request.use(
					(config: any) => {
						return config
					},
					(error: any) => {
						return Promise.reject(error)
					}
				)
			})
		}
	}

	validateHttp() {
		if (!this.http) throw new Error('HTTP client is not initialized')
	}

	handleResponse(response: AxiosResponse) {
		const { uf, localidade, logradouro, bairro } = response.data
		return {
			state: states[uf as keyof typeof states],
			city: localidade,
			street: logradouro,
			neighborhood: bairro,
		}
	}

	async getByZipCode(zipcode: string): Promise<TViaCepResponse> {
		this.validateHttp()
		const response = await this.http!.get(`/${zipcode}/json`)
		return this.handleResponse(response)
	}
}

export default Address
