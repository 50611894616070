import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import { api } from '@services'

import helpers from './helpers'

import { TCartItem, TCartItems, TContext } from './types'

const CartContext = createContext<TContext>({
	items: [],
	quantity: { items: 0, tickets: 0 },
	price: 0,
	discountedPrice: 0,
	add: () => {},
	remove: () => () => {},
	update: () => () => {},
	reset: () => {},
	recalculateDiscount: () => {},
})

export const CartProvider = ({ children }: React.PropsWithChildren) => {
	const [items, setItems] = useState<TCartItems>([])

	const [ticketsCount, price, discountedPrice] = items.reduce(helpers.toCountAndPrice, [0, 0, 0])

	const quantity = useMemo(
		() => ({
			items: items.length,
			tickets: ticketsCount,
		}),
		[items.length, ticketsCount]
	)

	const updateItem = (id: string, amount: number) => {
		setItems((prevCart) => {
			const byId = (item: TCartItem) => item._id === id
			const index = prevCart.findIndex(byId)
			const item = prevCart[index]

			if (item) {
				if (amount <= item.limitTickets) item.tickets = amount
				if (amount <= 0) item.tickets = 0
			}

			return [...prevCart]
		})
	}

	const update = useCallback((id: string) => (amount: number) => updateItem(id, amount), [])

	const add = useCallback(
		(newItem: TCartItem) => {
			const forSameId = (item: TCartItem) => item._id === newItem._id
			const alreadyAddedItem = items.find(forSameId)

			if (alreadyAddedItem) updateItem(newItem._id, alreadyAddedItem.tickets + newItem.tickets)
			else setItems((prevCart) => [...prevCart, newItem])
		},
		[items]
	)

	const remove = useCallback(
		(id: string) => () => {
			const byDifferentIds = (item: TCartItem) => item._id !== id
			setItems((prevCart) => prevCart.filter(byDifferentIds))
		},
		[]
	)

	const reset = useCallback(() => setItems([]), [])

	const recalculateDiscount = useCallback(async () => {
		const promises = items.map((item) => api.campaign.checkDiscount(item._id))
		const discounts = await Promise.all(promises)
		setItems((prevCart) =>
			prevCart.map((item, index) => ({
				...item,
				useDiscountFirstUsers: discounts[index].applyDisccount,
			}))
		)
	}, [items])

	const contextValue = useMemo(
		() => ({
			items,
			quantity,
			price,
			discountedPrice,
			add,
			remove,
			update,
			reset,
			recalculateDiscount,
		}),
		[items, quantity, price, discountedPrice, add, remove, update, reset, recalculateDiscount]
	)

	return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
}

export const useCartContext = () => useContext(CartContext)
