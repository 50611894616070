import { TWinners } from '@customTypes/entities'

import Api from '.'

class Drawn extends Api {
	getAll(params?: GenericObject): Promise<TWinners> {
		return this.get('draw', {
			params: {
				page: 1,
				limit: 1000,
				...params,
			},
		})
	}
}

export default Drawn
