import { AxiosInstance, AxiosResponse } from 'axios'

import DOM from '@utils/DOM'

import { TOptions } from './types'

class Api {
	http: AxiosInstance | undefined = undefined

	constructor() {
		if (DOM.isClient()) {
			import('axios').then((axios) => {
				this.http = axios.default.create()
				this.http!.defaults.baseURL = `${process.env.GATSBY_API}/api`
				this.http!.defaults.headers.common['Content-Type'] = 'application/json'
				this.http!.interceptors.request.use(
					(config) => {
						return config
					},
					(error) => {
						return Promise.reject(error)
					}
				)
			})
		}
	}

	validateHttp() {
		if (!this.http) throw new Error('HTTP client is not initialized')
	}

	handleResponse(response: AxiosResponse) {
		return response.data
	}

	jwtToken() {
		return localStorage.getItem('access-token')
	}

	composeUrl(endpoint: string, objectParams?: GenericObject) {
		if (objectParams) {
			const entries = Object.entries(objectParams)

			const stringParams = entries.reduce((currentParams, [key, value]) => {
				const shouldAppendParam = value !== undefined && value !== null
				if (!shouldAppendParam) return currentParams

				const param = `${key}=${JSON.stringify(value)}`
				if (currentParams) return [currentParams, param].join('&')

				return param
			}, '')

			return [endpoint, stringParams].join('?')
		}

		return endpoint
	}

	async get(endpoint: string, { headers, params, addAuthorization }: TOptions = {}) {
		this.validateHttp()

		const response = await this.http!.get(this.composeUrl(endpoint, params), {
			headers: {
				...headers,
				Authorization: addAuthorization && `Bearer ${this.jwtToken()}`,
			},
		})

		return this.handleResponse(response)
	}

	async post(endpoint: string, { headers, params, body, addAuthorization }: TOptions = {}) {
		this.validateHttp()

		const response = await this.http!.post(this.composeUrl(endpoint, params), body, {
			headers: {
				...headers,
				Authorization: addAuthorization && `Bearer ${this.jwtToken()}`,
			},
		})

		return this.handleResponse(response)
	}

	async put(endpoint: string, { headers, params, body, addAuthorization }: TOptions = {}) {
		this.validateHttp()

		const response = await this.http!.put(this.composeUrl(endpoint, params), body, {
			headers: {
				...headers,
				Authorization: addAuthorization && `Bearer ${this.jwtToken()}`,
			},
		})

		return this.handleResponse(response)
	}

	async patch(endpoint: string, { headers, params, body, addAuthorization }: TOptions = {}) {
		this.validateHttp()

		const response = await this.http!.patch(this.composeUrl(endpoint, params), body, {
			headers: {
				...headers,
				Authorization: addAuthorization && `Bearer ${this.jwtToken()}`,
			},
		})

		return this.handleResponse(response)
	}

	async delete(endpoint: string, { headers, params, addAuthorization }: TOptions = {}) {
		this.validateHttp()

		const response = await this.http!.delete(this.composeUrl(endpoint, params), {
			headers: {
				...headers,
				Authorization: addAuthorization && `Bearer ${this.jwtToken()}`,
			},
		})

		return this.handleResponse(response)
	}
}

export default Api
