import { TThemeConfig } from '@customTypes/entities'

export const DEFAULT_CUSTOMIZATIONS: TThemeConfig = {
	_id: 'default',
	primaryColor: '#312e97',
	secondaryColor: '#e9e9f7',
	accentColor: '#26bb2a',
	errorColor: '#ff5252',
	warningColor: '#eaed35',
	isDarkTheme: false,
}
