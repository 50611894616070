import { CampaignProvider } from './Campaign'
import { CartProvider } from './Cart'
import { CustomizationProvider } from './Customization'
import { ModalProvider } from './Modal'
import { PurchaseProvider } from './Purchase'
import { UserProvider } from './User'

const ContextProviders = ({ children }: React.PropsWithChildren) => (
	<CustomizationProvider>
		<ModalProvider>
			<UserProvider>
				<CampaignProvider>
					<PurchaseProvider>
						<CartProvider>{children}</CartProvider>
					</PurchaseProvider>
				</CampaignProvider>
			</UserProvider>
		</ModalProvider>
	</CustomizationProvider>
)

export default ContextProviders
