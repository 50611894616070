import { TBanners } from '@customTypes/entities'

import Api from '.'

class Banner extends Api {
	getAll(params?: GenericObject): Promise<TBanners> {
		return this.get('banner', {
			addAuthorization: true,
			params: {
				page: 1,
				limit: 1000,
				...params,
			},
		}).catch((e) => {
			console.log(e)
			return []
		})
	}

	create(body: FormData) {
		return this.post('banner', {
			addAuthorization: true,
			body,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}

	update(id: string, body: FormData) {
		return this.patch(`banner/${id}`, {
			addAuthorization: true,
			body,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}

	deleteById(id: string) {
		return this.delete(`banner/${id}`, {
			addAuthorization: true,
		})
	}
}

export default Banner
