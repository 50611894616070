import { TTopBuyers } from '@customTypes/entities'

import { TWinnerPreview } from './types'

import Api from '.'

class Ticket extends Api {
	getWinnerPreview(campaignId: string, ticketNumber: string): Promise<TWinnerPreview> {
		return this.get(`ticket/winner-preview/${campaignId}/${ticketNumber}`, {
			addAuthorization: true,
		})
	}

	getTopBuyers(): Promise<TTopBuyers> {
		return this.get(`ticket/top-buyers`)
	}
}

export default Ticket
