import number from '@utils/number'

import {
	TAuthResponse,
	TCompleteRegistrationPayload,
	TForgotPasswordPayload,
	TResetPasswordPayload,
	TSignInPayload,
	TSignUpPayload,
	TUpdatePasswordPayload,
} from './types'

import Api from '.'

class User extends Api {
	me(): Promise<TAuthResponse> {
		return this.get('user/me', { addAuthorization: true }).catch((error) => {
			console.log(error.message)
		})
	}

	activateUser(token: string): Promise<TAuthResponse> {
		return this.patch(`user/activate-email/${token}`).catch((error) => {
			console.log(error.message)
		})
	}

	signIn({ email, password }: TSignInPayload) {
		return this.post('user/sign-in', {
			addAuthorization: true,
			body: { email, password },
		}).catch((_) => {
			throw new Error('Erro ao entrar')
		})
	}

	signUp({ email, password, birthDate, emailMarketing, affiliateId }: TSignUpPayload) {
		return this.post('user/sign-up', {
			addAuthorization: true,
			body: {
				email,
				password,
				birthDate,
				canSendNews: emailMarketing,
				affiliateId,
			},
		}).catch((_) => {
			throw new Error('Erro ao criar usuário')
		})
	}

	completeRegistration({ name, cpf, phone }: TCompleteRegistrationPayload) {
		return this.patch('user/finish-register', {
			addAuthorization: true,
			body: {
				fullName: name,
				phone: number.extractDigits(phone),
				cpf: number.extractDigits(cpf),
			},
		}).catch((_) => {
			throw new Error('Erro ao completar cadastro')
		})
	}

	forgotPassword({ email }: TForgotPasswordPayload) {
		return this.post('user/forgot-password', {
			addAuthorization: true,
			body: { email },
		}).catch((_) => {
			throw new Error('Erro ao solicitar mudança de senha')
		})
	}

	resetPassword({ token, newPassword }: TResetPasswordPayload) {
		return this.post('user/reset-password', {
			addAuthorization: true,
			body: { token, newPassword },
		}).catch((_) => {
			throw new Error('Erro ao recriar a senha')
		})
	}

	updatePassword({ currentPassword, newPassword }: TUpdatePasswordPayload) {
		return this.post('user/change-password', {
			addAuthorization: true,
			body: { currentPassword, newPassword },
		}).catch((_) => {
			throw new Error('Erro ao mudar a senha')
		})
	}
}

export default User
