import chroma from 'chroma-js'

import { TThemeConfig } from '@customTypes/entities'

import { TCreateVariations } from './types'

const getStoredCustomizations = () => {
	const rawStoredCustomizations = localStorage.getItem('customizations')
	const theme: TThemeConfig = rawStoredCustomizations ? JSON.parse(rawStoredCustomizations) : {}
	return theme
}

const saveCurrentCustomizations = (customizations: TThemeConfig) => {
	localStorage.setItem('customizations', JSON.stringify(customizations))
}

const createColors = (customizations: TThemeConfig) => {
	if (customizations) {
		const root = document.documentElement
		const suffix = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]

		const createVariations = ({ name, color, amount }: TCreateVariations) => {
			if (color) {
				const colors = ['white', color, 'black']
				if (customizations.isDarkTheme) colors.reverse()

				const scale = chroma.scale(colors).mode('lrgb').colors(13)
				scale.shift()
				scale.pop()

				for (let i = 0; i < amount; i++) {
					root.style.setProperty(`--color-${name}-${suffix[i]}`, scale[i])
				}
			}
		}

		createVariations({
			name: 'primary',
			color: customizations.primaryColor,
			amount: 11,
		})

		createVariations({
			name: 'secondary',
			color: customizations.secondaryColor,
			amount: 11,
		})

		createVariations({
			name: 'accent',
			color: customizations.accentColor,
			amount: 11,
		})

		createVariations({
			name: 'error',
			color: customizations.errorColor,
			amount: 11,
		})

		createVariations({
			name: 'warning',
			color: customizations.warningColor,
			amount: 11,
		})

		root.style.setProperty(
			'--color-background-primary',
			customizations.isDarkTheme ? '#121212' : '#ffffff'
		)

		root.style.setProperty(
			'--color-background-secondary',
			customizations.isDarkTheme ? '#000000' : '#f2f2f2'
		)

		const textColors = customizations.isDarkTheme
			? ['#191721', '#47454d', '#65646a', '#959499', '#b8b7ba', '#e8e8e9']
			: ['#e8e8e9', '#b8b7ba', '#959499', '#65646a', '#47454d', '#191721']

		textColors.forEach((textColor, index) => {
			root.style.setProperty(`--color-text-${suffix[index]}`, textColor)
		})
	}
}

export default { getStoredCustomizations, saveCurrentCustomizations, createColors }
