import { toast } from 'react-toastify'

const copyToClipBoard = async (value?: string) => {
	if (!value) return

	try {
		navigator.clipboard.writeText(value)
	} catch (e) {
		document.execCommand('copy', true, value)
	} finally {
		toast.info('Copiado para a área de transferência')
	}
}

const isClient = () => {
	return typeof window !== 'undefined'
}

const resetURL = () => {
	window.history.replaceState({}, document.title, window.location.origin)
}

export default { copyToClipBoard, resetURL, isClient }
