import { TThemeConfig } from '@customTypes/entities'

import Api from '.'

class Customization extends Api {
	getData(): Promise<TThemeConfig> {
		return this.get('theme-config')
	}

	update(id: string, body: FormData) {
		return this.patch(`theme-config/${id}`, {
			addAuthorization: true,
			body,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}
}

export default Customization
