import React from 'react'

import ContextProviders from './src/contexts'

import './src/styles/global.scss'

import '@fontsource/poppins/100.css'
import '@fontsource/poppins/200.css'
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/800.css'
import '@fontsource/poppins/900.css'

export const wrapRootElement = ({ element }) => <ContextProviders>{element}</ContextProviders>
