import { TCampaigns } from '@customTypes/entities'

import { TCheckDiscount } from './types'

import Api from '.'

class Campaign extends Api {
	getAll(params?: GenericObject): Promise<TCampaigns> {
		return this.get('campaign', {
			addAuthorization: true,
			params: {
				page: 1,
				limit: 1000,
				...params,
			},
		})
	}

	create(body: FormData) {
		return this.post('campaign', {
			addAuthorization: true,
			body,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}

	checkDiscount(userId: string): Promise<TCheckDiscount> {
		return this.get(`campaign/check-disccount/${userId}`, {
			addAuthorization: true,
		})
	}

	update(id: string, body: FormData) {
		return this.patch(`campaign/${id}`, {
			addAuthorization: true,
			body,
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	}

	start(id: string) {
		return this.patch(`campaign/start/${id}`, {
			addAuthorization: true,
		})
	}

	finish(id: string, body: GenericObject) {
		return this.patch(`campaign/finish/${id}`, {
			addAuthorization: true,
			body,
		})
	}

	deleteById(id: string) {
		return this.delete(`campaign/${id}`, {
			addAuthorization: true,
		})
	}
}

export default Campaign
